
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;900&family=Kaushan+Script&family=Varela+Round&display=swap');
 * {
 	padding: 0px;
 	margin: 0px;
 	box-sizing: border-box;
 }

.fullWindowWidth {
	width: calc(100vw - var(--scrollbar-width));
	max-width: calc(100vw - var(--scrollbar-width));
}

.noNavHeight {
	height: calc(100vh - 45.7px);
	max-height: calc(100vh - 45.7px);
}

.logo {
	height: 35px;
	width: 110px;
	top: -30%;
	object-fit: cover;
	object-position: 50% 45%;
}

/*navbar*/


	.navLink {
		color: white;
		text-decoration: none;
		font-size: 15px;
		font-family: "Inter", "Verdana", sans-serif;
	}

	.navLink:hover {
		color: #f36b6e;
	}


/*home_page*/
	#homepic1 {
		object-fit: cover;
		width: 100%;
		height: 100vh;
	}

	.homeTitle {
		font-size: 80px;
	}

	.homeDiv {

	}

/*login_page*/
	@media (min-width: 769px) {
		#loginCard {
			
		}
		#loginPic {
			object-fit: cover;
			height: 100vh;
			max-height: 100vh;
			width: 60vw;
			max-width: 60vw;

		}
		.loginDiv {
			max-height: 100vh;
		}
		
	}

/*fonts*/
	.text-res1 {
		font-size: 5vw;
	}

	.kaushan {
		font-family: "Kaushan Script";
	}

	.inter {
		font-family: "Inter";
	}
	.weight-900 {
		font-weight: 
	}

	.varela-round {
		font-family: "Varela Round"
	}

	.fs-10 {
		font-size: 10px;
	}

	.fs-12 {
		font-size: 12px;
	}

	.fs-16 {
		font-size: 16px;
	}

	.fs-24 {
		font-size: 24px;
	}
	


/*colors*/

	.black {
		color: black;
	}

	.bg-1 {
		background-color: #1b667e;
	}

	.bg-2 {
		background-color: #548ea3;
	}

	.bg-3 {
		background-color: #45b4d4;
	}

	.bg-4 {
		background-color: #99d6e6;
	}

	.bg-5 {
		background-color: #dce4ec;
	}

	.bg-6 {
		background-color: #86e3ce;
	}

	.bg-7 {
		background-color: #d0e6a5;
	}

	.bg-8 {
		background-color: #ffdd94;
	}

	.bg-9 {
		background-color: #fa897b;
	}

	.bg-10 {
		background-color: #ccabd8;
	}


	.color-1 {
		color: #1b667e;
	}

	.color-2 {
		color: #548ea3;
	}

	.color-3 {
		color: #45b4d4;
	}

	.color-4 {
		color: #99d6e6;
	}

	.color-5 {
		color: #dce4ec;
	}

	.bg-6 {
		color: #86e3ce;
	}

	.bg-7 {
		color: #d0e6a5;
	}

	.bg-8 {
		color: #ffdd94;
	}

	.bg-9 {
		color: #fa897b;
	}

	.bg-10 {
		color: #ccabd8;
	}

	.bg-custRed {
		background-color: #f36b6e;
	}

	.white {
		color: white;
	}

a {
	text-decoration: none;
	color: black;
}

Link {
	color:black;
}

.button:hover {
	background-color: #f36b6e;
}

/*borders*/
	.all-border-1 {
		border: 2px solid #1b667e;
	}

	.all-border-2 {
		border: 2px solid #548ea3;
	}

	.all-border-3 {
		border: 2px solid #45b4d4;
	}

	.all-border-4 {
		border: 2px solid #99d6e6;
	}

	.all-border-5 {
		border: 2px solid #dce4ec;
	}

	.border-radius-1 {
		border-radius:  8px;
	}

	.no-border {
		border: none;
	}

	.border-left {
		border-left: 20px solid;
	}

	.border-right {
		border-right: 20px solid;
	}

	.border-top {
		border-top: 20px solid;
	}

	.border-bot {
		border-bottom: 20px solid;
	}

.cover {
	object-fit: cover;
}

.overflow-auto {
	overflow:	auto;
}

/* Bootstrap Overrides */
	.form-control:focus {
	  box-shadow: none;
	  border-bottom: solid #548ea3 3px;
	}

/* Cursor CSS */
	.default {
		cursor: default;
	}

/* Text */
	.text-justify {
		text-align: justify;
	}

.darkbg-white-text {
	background-color: rgba(0, 0, 0, 0.5);
	width: 100%;
	color: white;

}

.height-50 {
	height: 50vh;
	min-height: 50vh
}

.no-pic {
	display: flex;
	height: 25vh;
	width: 25vh;
	border: 4px white solid;
	border-radius: 50%;

	color: white;
	font-size: 17vh;
}